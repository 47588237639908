.editResumeTitleModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.editResumeTitleModal-dark {
  background-color: #136F63;
  color: white;
}

.editResumeTitleModal-light {
  background-color: white;
  color: black
}

.editResumeTitleModal form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.editResumeTitleModal form div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editResumeTitleModal form button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.editResumeTitleModal-dark form button {
  background-color: #B74F6F;
  color: white;
}

.editResumeTitleModal-dark form button:hover {
  background-color: #EF946C;
}

.editResumeTitleModal-light form button {
  background-color: #EF946C;
  color: black;
}

.editResumeTitleModal-light form button:hover {
  background-color: #B74F6F;
}
