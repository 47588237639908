.changeUserSkillIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.changeUserSkillIcon img {
  max-width: 400px;
  margin: 30px 0;
}

.changeUserSkillIcon-dark {
  background-color: #136F63;
  color: white
}

.changeUserSkillIcon-light {
  background-color: white;
  color: black
}

.changeUserSkillIcon form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.changeUserSkillIcon form button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.changeUserSkillIcon-dark form button {
  background-color: #B74F6F;
  color: white;
}

.changeUserSkillIcon-light form button {
  background-color: #EF946C;
  color: black;
}
