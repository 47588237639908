.deleteEmployerModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  max-width: 30vw;
  text-align: center;
}

.deleteEmployerModal-dark {
  background-color: #136F63;
  color: white;
}

.deleteEmployerModal-light {
  background-color: white;
  color: black;
}

.deleteEmployerModal-dark h2 span {
  color: #B74F6F
}

.deleteEmployerModal-light h2 span {
  color: red
}

.deleteEmployerModal-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.deleteEmployerModal-buttons button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 30%;
}

.deleteEmployerModal-buttonsDelete-dark {
  background-color: #B74F6F;
  color: white
}

.deleteEmployerModal-buttonsDelete-dark:hover {
  background-color: red;
}

.deleteEmployerModal-buttonsDelete-light {
  background-color: red;
  color: white
}

.deleteEmployerModal-buttonsDelete-light:hover {
  background-color: #B74F6F;
}

.deleteEmployerModal-buttonsCancel {
  background-color: green;
  color: white;
}
