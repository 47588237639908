.profilePage-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.profilePage-dark {
  background-color: #136F63;
}

.profilePage-light {
  background-color: white;
}

.profilePage-info {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
}

.profilePage-info-light {
  border: 1px solid #136F63;
}

.profilePage-info-dark {
  border: 1px solid white;
}

.profilePage-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profilePage-user table th {
  text-align: left;
  padding-right: 10px;
}

.profilePage-user img {
  height: 150px;
}

.profilePage-userButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profilePage-userButtons button {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.profilePage-userButtons-dark button {
  background-color: #B74F6F;
  color: white;
}

.profilePage-userButtons-dark button:hover {
  background-color: #EF946C;
}

.profilePage-userButtons-light button {
  background-color: #EF946C;
  color: black;
}

.profilePage-userButtons-light button:hover {
  background-color: #B74F6F;
}

.profilePage-socialMedia-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profilePage-socialMedia-buttons button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.profilePage-socialMedia-buttons-light button {
  background-color: #EF946C;
  color: black;
}

.profilePage-socialMedia-buttons-light button:hover {
  background-color: #B74F6F;
}

.profilePage-socialMedia-buttons-dark button {
  background-color: #B74F6F;
  color: white;
}

.profilePage-socialMedia-buttons-dark button:hover {
  background-color: #EF946C;
}

.profilePage-projects {
  display: flex;
  flex-direction: column;
}

.profilePage-projectsButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profilePage-projectsButtons button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.profilePage-projectsButtons-light button {
  background-color: #EF946C;
  color: black;
}

.profilePage-projectsButtons-light button:hover {
  background-color: #B74F6F;
}

.profilePage-projectsButtons-dark button {
  background-color: #B74F6F;
  color: white;
}

.profilePage-projectsButtons-dark button:hover {
  background-color: #EF946C;
}


.profilePage-skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.profilePage-skills-light {
  border: 1px solid #136F63;
}

.profilePage-skills-dark {
  border: 1px solid white;
}

.profilePage-skillButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profilePage-skillButton button {
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.profilePage-skillButton-light button {
  background-color: #EF946C;
  color: black;
}

.profilePage-skillButton-light button:hover {
  background-color: #B74F6F;
}

.profilePage-skillButton-dark button {
  background-color: #B74F6F;
  color: white;
}

.profilePage-skillButton-dark button:hover {
  background-color: #EF946C;
}
