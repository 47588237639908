.resumeEmployerListItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.resumeEmployerListItem p {
  margin: 0 0 5px 0;
  font-size: 14px;
}

.resumeEmployerListItem-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumeEmployerListItem ul {
  margin: 0 0 10px 0;
}

.resumeEmployerListItem li {
  font-size: 14px;
}
