.notFoundPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #371B0E;
  height: calc(100vh - 150px);
}

.notFoundPage h1 {
  color: white;
}

.notFoundPage img {
  width: 600px;
}
