.projectListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectListItem h2 {
  margin: 0;
}

.projectListItem img {
  width: 400px;
  border: 1px solid black;
  margin-bottom: 10px;
  margin-top: 10px;
}

.projectListItem p {
  margin: 5px 0;
}

.projectListItem a {
  text-decoration: none;
  margin: 0;
}

.projectListItem a:hover {
  text-decoration: underline;
}

.projectListItem-light a {
  color: #136F63
}

.projectListItem-dark a {
  color: white
}

.projectListItem-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.projectListItem-links a {
  text-decoration: none;
}

.projectListItem-links a:hover {
  text-decoration: underline;
}

.projectListItem-links-light a {
  color: #136F63
}

.projectListItem-links-dark a {
  color: white
}
