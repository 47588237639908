#flashlight {
  --pointerX: 50vw;
  --pointerY: 50vh
}

#flashlight:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  background: radial-gradient(circle 10vmax at var(--pointerX) var(--pointerY), rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0.95) 100%);
}

.ultraDarkPage p {
  margin: 0;
}

.ultraDarkPage-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ultraDarkPage-content div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 45vw;
  height: 45vh;
  margin-bottom: 20px;
}

.ultraDarkPage-second img {
  height: 200px;
}

.ultraDarkPage-content i {
  color: red;
  font-size: 56px;
}

.ultraDarkPage-first p {
  color: transparent;
}

.ultraDarkPage-first p:hover {
  color: black;
  cursor: help;
}

.ultraDarkPage-third p {
  color: transparent;
}

.ultraDarkPage-third p:hover {
  color: black;
  cursor: help;
}

.ultraDarkPage-forth button {
  font-family: 'Creepster', cursive;
  font-size: 36px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #660000;
}
