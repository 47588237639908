.landingPage {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 150px);
}

.landingPage-userSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  width: 40%;
}

.landingPage-userSelect-light {
  background-color: white;
}

.landingPage-userSelect-dark {
 background-color: black;
}

.landingPage-user img {
  width: 300px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-bottom: 10px;
}

.landingPage-user {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landingPage-user h3, p, a {
  margin: 5px 0 0 5px;
}

.landingPage-user a {
  text-decoration: none;
}

.landingPage-user a:hover {
  text-decoration: underline;
}

.landingPage-user-light a {
  color: #136F63
}

.landingPage-user-dark {
  color: white
}

.landingPage-user-dark a {
  color: #EF946C
}

.landingPage-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landingPage-content-light {
  background-color: white;
}

.landingPage-content-dark {
  background-color: #136F63;
}

.landingPage-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.landingPage-select button {
  background-color: none;
  border: none;
  cursor: pointer;
  width: 90%;
  font-size: 20px;
  padding: 20px;
  margin-bottom: 10px;
}

.landingPage-select-light button {
  background-color: white;
  border: 1px solid #EF946C;
  border-radius: 20px;
}

.landingPage-select-light button:hover {
  background-color: #EF946C;
}

.landingPage-select-dark button {
  background-color: black;
  border: 1px solid #B74F6F;
  border-radius: 20px;
  color: white;
}

.landingPage-select-dark button:hover {
  background-color: #B74F6F;
}

@media screen and (max-width: 900px) {
  .landingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landingPage-userSelect {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .landingPage-user img {
    width: 150px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-bottom: 10px;
  }
}
