.resumeSkillForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  min-width: 20vw;
}

.resumeSkillForm-dark {
  background-color: #136F63;
  color: white;
}

.resumeSkillForm-light {
  background-color: white;
  color: black
}

.resumeSkillForm form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.resumeSkillForm-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
}

.resumeSkillForm-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  justify-content: space-around;
  width: 100%;
}

.resumeSkillForm-buttons button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.resumeSkillForm-buttons-dark button {
  background-color: #B74F6F;
  color: white;
}

.resumeSkillForm-buttons-dark button:hover {
  background-color: #EF946C;
}

.resumeSkillForm-buttons-light button {
  background-color: #EF946C;
  color: black;
}

.resumeSkillForm-buttons-light button:hover {
  background-color: #B74F6F;
}
