.hidden {
  display: none;
}

.navigation {
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}

.navigation-light {
  background-color: #7192BE;
}

.navigation-dark {
  background-color: #134074;
}

.navigation a {
  margin-left: 20px;
  font-size: 18px;
  text-decoration: none;
}

.navigation-light h1 {
  color: black;
}

.navigation-dark h1 {
  color: white;
}

.navigation div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 30px;
}

.navigation-dark button {
  color: white;
}

.navigation-light button {
  color: black;
}

.profileButton {
  position: relative;
}

.profileButton i {
  font-size: 36px;
}

.profile-dropdown {
  position: absolute;
  z-index: 1;
  list-style-type: none;
  padding: 5px 10px;
  border: 1px solid black;
  font-size: 16px;
  top: 50px;
  right: 10px;
}

.profile-dropdown li {
  margin-bottom: 5px;
  margin-top: 5px;
}

.profile-dropdown button {
  font-size: 16px;
  padding: 0;
}

.profileButton-dark ul {
  background-color: black;
  color: white;
}

.profileButton-light ul {
  background-color: white;
  color: black;
}

.profileButton-line {
  padding-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.profileButton-line-dark {
  border-bottom: 2px solid white;
}

.profileButton-line-light {
  border-bottom: 2px solid #848A87;
}

.profileButton a {
  text-decoration: none;
  margin: 0;
}

.profileButton a:hover {
  text-decoration: underline;
}

.profileButton-dark a {
  color: white;
}

.profileButton-light a {
  color: black;
}

@media screen and (max-width: 1200px) {
  .ultraDarkMode button {
    display: none;
  }
}
