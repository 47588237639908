.socialMediaListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 20px;
  margin: 5px 0;
}

.socialMediaListItem-dark {
  border: 1px solid white;
}

.socialMediaListItem-light {
  border: 1px solid #136F63;
}

.socialMediaListItem th {
  text-align: left;
}

.socialMediaListItem-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialMediaListItem-buttons button {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.socialMediaListItem-buttons-dark button {
  background-color: #B74F6F;
  color: white;
}

.socialMediaListItem-buttons-dark button:hover {
  background-color: #EF946C;
}

.socialMediaListItem-buttons-light button {
  background-color: #EF946C;
  color: black;
}

.socialMediaListItem-buttons-light button:hover {
  background-color: #B74F6F;
}
