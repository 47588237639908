.deleteSkillModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.deleteSkillModal h2 span {
  color: red
}

.deleteSkillModal-dark h2 span {
  color: #B74F6F
}

.deleteSkillModal-dark {
  background-color: #136F63;
  color: white
}

.deleteSkillModal-light {
  background-color: white;
  color: black
}

.deleteSkillModal button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 30%;
}

.deleteSkillModal div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.deleteSkillModal-yes-light {
  background-color: red;
  color: white
}

.deleteSkillModal-yes-dark {
  background-color: #B74F6F;
  color: white
}

.deleteSkillModal-no {
  background-color: green;
  color: white
}
