.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.footer-light {
  background-color: #7192BE;
}

.footer-light p {
  color: black
}

.footer-dark {
  background-color: #134074;
}

.footer-dark p {
  color: white
}
