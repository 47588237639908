.deleteProjectModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.deleteProjectModal h2 span {
  color: red;
}

.deleteProjectModal-dark h2 span {
  color: #B74F6F;
}

.deleteProjectModal-dark {
  background-color: #136F63;
  color: white
}

.deleteProjectModal-light {
  background-color: white;
  color: black
}

.deleteProjectModal button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 30%;
}

.deleteProjectModal div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.deleteProjectModal-yes-light {
  background-color: red;
  color: white
}

.deleteProjectModal-yes-dark {
  background-color: #B74F6F;
  color: white;
}

.deleteProjectModal-no {
  background-color: green;
  color: white
}
