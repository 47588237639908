.deleteProjectBulletPointModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  max-width: 30vw;
}

.deleteProjectBulletPointModal {
  text-align: justify;
}

.deleteProjectBulletPointModal h2 span {
  color: red;
}

.deleteProjectBulletPointModal-dark h2 span {
  color: #B74F6F
}

.deleteProjectBulletPointModal-dark {
  background-color: #136F63;
  color: white;
}

.deleteProjectBulletPointModal-light {
  background-color: white;
  color: black
}

.deleteProjectBulletPointModal-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.deleteProjectBulletPointModal-buttons button {
  margin-top: 20px;
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 30%;
}

.deleteProjectBulletPointModal-delete-dark {
  background-color: #B74F6F;
  color: white
}

.deleteProjectBulletPointModal-delete-dark:hover {
  background-color: red;
}

.deleteProjectBulletPointModal-delete-light {
  background-color: red;
  color: white
}

.deleteProjectBulletPointModal-delete-light:hover {
  background-color: #B74F6F;
}

.deleteProjectBulletPointModal-cancel {
  background-color: green;
  color: white
}
