* {
  font-family: 'Inter', sans-serif;
}

/* #root {
  width: 100%;
} */

nav, main, footer {
  max-width: 1440px;
  margin: 0 auto;
}

main {
  min-height: calc(100vh - 171px);
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.error {
  color: red;
}

footer {
  height: 50px;
}
